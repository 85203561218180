<template>
  <div class="cover">
    <h1 class="heading">Coming soon! ⌛</h1>
    <p class="subheading">A wrist, but with a twist!</p>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>
.cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1A1514; /* Brown background */
  color: #fff; /* White text */
  text-align: center;
}

.heading {
  font-size: 4rem; /* Large font size for heading */
  font-weight: bold;
}

.subheading {
  font-size: 1.5rem; /* Smaller font for subheading */
  margin-top: 1rem;
}
</style>
